.item {
	height: 270px;
	width: 184px;
	display: inline-table;
  padding: 2px;
}

.item-title-wrapper {
	margin: 0px auto;
	width: 150px;
	padding-top: 5px;
}

.item-title {
	font-weight: bold;
}

.item-body-wrapper {
	margin: 0px auto;
	width: 150px;
	padding: 2px;
}

.item-picture {
	margin: 0px auto;
	height: 150px;
	width: 150px;
	border: 1px solid hsl(240,2%,25%);
	box-shadow: 1px 1px 1px hsl(240,5%,25%);
}

.item-picture img:hover {
	opacity: .5;
}

.item-details {

	
}

.item-description {
	
}
